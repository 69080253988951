<template>
  <ActionForm
    :request-fields="requestFields"
    :validation-schema="validationSchema"
    :response-errors="fieldsErrors"
    @validate="onPassedValidation"
  >
    <template #default="{ errors, action }">
      <FInput
        v-model="requestFields.email"
        :placeholder="t('merchantSettings.changeEmail.label.emailAddress')"
        :validation-error="errors.email"
      >
        <template #prefix>
          <AppIcon name="email" size="18px" />
        </template>
      </FInput>

      <div style="margin-top: 20px;" class="d-flex justify-content-between">
        <FButton
          size="small"
          class="flex-1"
          no-margin
          @click="action"
        >
          {{ t('common.continue') }}
        </FButton>
        <FButton
          size="small"
          class="flex-1"
          type="plain"
          no-margin
          @click="$emit('cancel')"
        >
          {{ t('common.cancel') }}
        </FButton>
      </div>
    </template>
  </ActionForm>
</template>

<script>
import { defineComponent } from 'vue';

import { ActionForm } from '@/validation';
import { emailRule } from '@/validation/rules';
import { fieldValidation } from '@/validation/schemas';
import { i18n } from '@/plugins/localization';

import { changeEmail } from '../api';

export default defineComponent({
  components: {
    ActionForm,
  },
  emits: ['success', 'cancel'],
  setup(props, { emit }) {
    const { t } = i18n.global;
    const {
      validationSchema,
      requestFields,
      fieldsErrors,
      processError,
    } = fieldValidation({ fieldName: 'email', rule: emailRule });

    const onPassedValidation = async () => {
      const { isSuccess, errorObject } = await changeEmail(requestFields.email);
      if (isSuccess) {
        emit('success', {
          text: t('merchantSettings.notification.requestToChangeEmailSuccess'),
          step: 'verify',
          newEmail: requestFields.email,
        });
      } else {
        processError(errorObject, errorObject?.message, 'email');
      }
    };

    return {
      validationSchema,
      requestFields,
      fieldsErrors,

      onPassedValidation,
    };
  },
});
</script>
