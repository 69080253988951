<template>
  <div>
    <AppText
      class="font-medium"
      size="16px"
      mb="20px"
      variant="div"
    >
      {{ t('merchantSettings.security.recentActivity.title') }}
    </AppText>

    <SimpleTable :data="data" :columns="columns" :is-loading="isLoading">
      <template #main="{ item, column, row }">
        <div class="d-flex align-items-center">
          <template v-if="column.name === 'activity'">
            <div class="shield-icon">
              <AppIcon name="shield-grey" size="12px" is-img-tag />
            </div>
            <AppText> {{ t(`action.${item}`) }} </AppText>
          </template>

          <div v-if="column.name === 'ip' && row?.countryCode" class="flag-icon">
            <CountryFlag :value="row?.countryCode" />
            <AppText>{{ item }}</AppText>
          </div>

          <div v-if="item === '127.9.9.9'" class="flag-icon">
            <CustomFlag
              name="flag-tor"
              size="16px"
            />
            <AppText>{{ t('merchantSettings.security.recentActivity.unknown') }}</AppText>
          </div>

          <div v-if="column.name === 'sessionId'">
            <AppText>
              <template v-if="item">
                #{{ item }}
              </template>
              <template v-else>
                {{ t('merchantSettings.security.recentActivity.unknown') }}
              </template>
            </AppText>
          </div>

          <div v-if="column.name === 'ip' && item === 'Unknown'">
            <AppText>
              {{ t('merchantSettings.security.recentActivity.unknown') }}
            </AppText>
          </div>

          <DateFormatter
            v-if="column.name === 'updatedAt'"
            :format="dateTimeFormat"
            :date="item"
          />
        </div>
      </template>
    </SimpleTable>
  </div>
</template>

<script setup>
import SimpleTable from '@/components/MainTable/SimpleTable.vue';
import CountryFlag from '@/components/Icon/CountryFlag.vue';
import CustomFlag from '@/components/Icon/CustomFlag.vue';

import { i18n } from '@/plugins/localization';
import { useProfile } from '@/composables/useProfile';

defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Array,
    default: () => [],
  },
});

const { t } = i18n.global;
const { dateTimeFormat } = useProfile();

const columns = [
  { name: 'activity', title: t('merchantSettings.security.recentActivity.action'), width: 550 },
  { name: 'sessionId', title: t('merchantSettings.security.recentActivity.session'), width: 150 },
  {
    name: 'ip', title: t('merchantSettings.security.recentActivity.ip'), type: 'ip', width: 200,
  },
  {
    name: 'updatedAt', title: t('merchantSettings.security.recentActivity.date'), type: 'date', width: 170,
  },
];
</script>
