<template>
  <ActionForm
    :request-fields="requestFields"
    :validation-schema="validationSchema"
    :response-errors="fieldsErrors"
    @validate="onPassedValidation"
  >
    <template #default="{ errors, action }">
      <SplitInput
        v-model="requestFields.code"
        type="dashed"
        :state="errors.code ? 'error' : ''"
        is-auto-clear-error
        :error-message="errors.code?.message"
      />

      <div class="d-flex justify-content-between">
        <FButton
          size="small"
          class="flex-1"
          no-margin
          @click="action"
        >
          {{ t('common.continue') }}
        </FButton>
        <FButton
          size="small"
          class="flex-1"
          type="plain"
          no-margin
          @click="$emit('cancel')"
        >
          {{ t('common.cancel') }}
        </FButton>
      </div>
    </template>
  </ActionForm>
</template>

<script>
import { defineComponent } from 'vue';

import SplitInput from '@/components/Inputs/SplitInput.vue';

import { twoFaAuthentificate } from '@/api/auth';
import { ActionForm } from '@/validation';
import { codeRule } from '@/validation/rules';
import { fieldValidation } from '@/validation/schemas';

export default defineComponent({
  components: {
    SplitInput,
    ActionForm,
  },
  emits: ['next', 'cancel'],
  setup(props, { emit }) {
    const {
      validationSchema,
      requestFields,
      fieldsErrors,
      processError,
    } = fieldValidation({ fieldName: 'code', rule: codeRule });

    const onPassedValidation = async () => {
      const { isSuccess, errorObject } = await twoFaAuthentificate({
        totpCode: requestFields.code,
      });
      if (isSuccess) {
        emit('next', 'change');
      } else {
        processError(errorObject, 'wrongToken', 'code');
      }
    };

    return {
      validationSchema,
      requestFields,
      fieldsErrors,

      onPassedValidation,
    };
  },
});
</script>
