<template>
  <SmoothModal
    :model-value="isOpen"
    width="440px"
    :has-header="false"
    :has-close-button="!isAccountDeleted"
    :no-click-outside="isAccountDeleted"
    @update:model-value="closeModal"
  >
    <StepsWrapper
      v-if="!isAccountDeleted"
      :class="{
        'is-first-step': currentStep === steps[0],
      }"
      :icon="currentStep === 'confirm' ? 'trash-red-filled' : 'shield-2fa'"
      :steps="steps"
      :current-step="currentStep"
    >
      <template #title>
        <template v-if="currentStep === 'confirm'">
          {{ t('merchantSettings.modalToDeleteAccount.confirm.title') }}
        </template>
        <template v-else>
          {{ t('merchantSettings.modalToDeleteAccount.code.title') }}
        </template>
      </template>
      <template #description>
        <template v-if="currentStep === 'confirm'">
          {{ t('merchantSettings.modalToDeleteAccount.confirm.description') }}
        </template>
        <template v-else>
          {{ t('merchantSettings.modalToDeleteAccount.code.description') }}
        </template>
      </template>
      <template v-if="currentStep === 'confirm'">
        <div class="warnings">
          <div class="warning">
            <AppIcon name="warning" size="18px" is-img-tag />
            <AppText variant="div" :line-height="1.5">
              {{ t('merchantSettings.modalToDeleteAccount.confirm.warning1') }}
            </AppText>
          </div>

          <div class="warning">
            <AppIcon name="warning" size="18px" is-img-tag />
            <AppText variant="div" :line-height="1.5">
              {{ t('merchantSettings.modalToDeleteAccount.confirm.warning2') }}
            </AppText>
          </div>

          <div class="warning">
            <AppIcon name="warning" size="18px" is-img-tag />
            <AppText variant="div" :line-height="1.5">
              {{ t('merchantSettings.modalToDeleteAccount.confirm.warning3') }}
            </AppText>
          </div>
        </div>

        <AppText
          variant="div"
          :line-height="1.5"
          mt="20px"
          mb="40px"
        >
          {{ t('merchantSettings.modalToDeleteAccount.confirm.comment') }}
        </AppText>

        <div class="buttons">
          <FButton is-full-width type="danger" @click="currentStep = 'code'">
            {{ t('merchantSettings.modalToDeleteAccount.confirm.conwirmWithTotp') }}
          </FButton>
          <FButton is-full-width type="plain" @click="closeModal">
            {{ t('common.cancel') }}
          </FButton>
        </div>
      </template>
      <ActionForm
        v-if="currentStep === 'code'"
        :request-fields="requestFields"
        :validation-schema="validationSchema"
        :response-errors="fieldsErrors"
        class="form"
        @validate="onDeleteAccount"
      >
        <template #default="{ errors, action }">
          <SplitInput
            v-model="requestFields.code"
            type="dashed"
            :state="errors?.code ? 'error' : ''"
            is-auto-clear-error
            :error-message="errors.code?.message"
          />

          <div class="buttons">
            <FButton is-full-width type="danger" @click="action">
              {{ t('merchantSettings.modalToDeleteAccount.code.deteteAccount') }}
            </FButton>
            <FButton is-full-width type="plain" @click="closeModal">
              {{ t('common.cancel') }}
            </FButton>
          </div>
        </template>
      </ActionForm>
    </StepsWrapper>

    <div v-else style="padding-top: 30px;">
      <AppIcon name="check-gray-filled" size="40px" is-img-tag />

      <AppText
        variant="div"
        size="17px"
        class="font-medium"
        mt="20px"
      >
        {{ t('merchantSettings.modalToDeleteAccount.final.title') }}
      </AppText>

      <AppText
        variant="div"
        :line-height="1.5"
        mt="16px"
        mb="110px"
      >
        {{ t('merchantSettings.modalToDeleteAccount.final.description') }}
      </AppText>

      <FButton is-full-width @click="onGoHome">
        {{ t('merchantSettings.modalToDeleteAccount.final.returnToHome') }}
      </FButton>
    </div>
  </SmoothModal>
</template>

<script>
import { defineComponent, ref } from 'vue';

import SmoothModal from '@/components/SmoothModal';
import StepsWrapper from '@/components/Containers/StepsWrapper.vue';
import SplitInput from '@/components/Inputs/SplitInput.vue';

import { deleteAccount, logout } from '@/api/auth';
import { ActionForm } from '@/validation';
import { codeRule } from '@/validation/rules';
import { fieldValidation } from '@/validation/schemas';

export default defineComponent({
  name: 'DeleteAccountModal',
  components: {
    SplitInput,
    ActionForm,
    SmoothModal,
    StepsWrapper,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const isAccountDeleted = ref(false);
    const steps = ['confirm', 'code'];
    const currentStep = ref('confirm');

    const {
      validationSchema,
      requestFields,
      fieldsErrors,
      processError,
      clearState,
    } = fieldValidation({ fieldName: 'code', rule: codeRule });

    const onDeleteAccount = async () => {
      const { isSuccess, errorObject } = await deleteAccount(requestFields.code);
      if (isSuccess) {
        clearState();
        isAccountDeleted.value = true;
      } else {
        processError(errorObject, 'wrongToken', 'code');
      }
    };

    const closeModal = () => {
      currentStep.value = steps[0];
      clearState();
      emit('update:modelValue');
    };

    const onGoHome = () => {
      logout({
        pushToHome: true,
        useLogoutEndpoint: false,
      });
    };

    return {
      isAccountDeleted,
      steps,
      currentStep,

      closeModal,
      onDeleteAccount,
      onGoHome,

      validationSchema,
      requestFields,
      fieldsErrors,
    };
  },
});
</script>

<style lang="scss" scoped>
$error: map-get($colors, "error");

.warnings {
  margin-top: 20px;

  .warning {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
    background: lighten($error, 60%);
    padding: 16px;
    border-radius: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.buttons {
  display: flex;
  gap: 20px;
}

.form {
  margin-top: 30px;

  :deep(.text__style) {
    margin: 10px 0;
  }
}

.is-first-step {
  :deep(.step-wrapper--title) {
    padding-bottom: 6px;
  }
}
</style>
