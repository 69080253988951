<template>
  <div class="settings-email">
    <SettingsOption
      icon="email-black"
      :title="t('merchantSettings.changeEmail.label.emailAddress')"
      :description="secretEmail"
    >
      <template #append>
        <FButton
          v-if="!isEmailVerified"
          size="small"
          @click="openModal('verify')"
        >
          {{ t('merchantSettings.changeEmail.title.verifyEmail') }}
        </FButton>
        <FButton
          type="text"
          size="small"
          @click="openModal('code')"
        >
          {{ t('merchantSettings.changeEmail.title.changeEmail') }}
        </FButton>

        <SmoothModal
          v-model="isModalOpen"
          :has-header="false"
          width="440px"
        >
          <StepsWrapper
            :icon="currentStep === 'code' ? 'shield-2fa' : 'email-grey-filled'"
            :steps="steps"
            :current-step="currentStep"
          >
            <template #title>
              {{ title }}
            </template>
            <template #description>
              {{ description }}
            </template>

            <TransitionGroup name="show">
              <CodeStep
                v-if="currentStep === 'code'"
                @next="currentStep = 'change'"
                @cancel="isModalOpen = false"
              />
              <ChangeStep
                v-if="currentStep === 'change'"
                @success="onSuccess"
                @cancel="isModalOpen = false"
              />
              <VerifyStep
                v-if="currentStep === 'verify'"
                @success="onSuccess"
                @cancel="isModalOpen = false"
              />
            </TransitionGroup>
          </StepsWrapper>
        </SmoothModal>
      </template>
    </SettingsOption>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import SmoothModal from '@/components/SmoothModal';
import StepsWrapper from '@/components/Containers/StepsWrapper.vue';

import { getSettings } from '@/api/merchant';
import { emitter } from '@/composables/useBus';
import { useNotifications } from '@/composables/useNotifications';
import { useUser } from '@/composables/useUser';
import { i18n } from '@/plugins/localization';

import SettingsOption from '../../../components/SettingsOption.vue';

import ChangeStep from './ChangeStep.vue';
import CodeStep from './CodeStep.vue';
import VerifyStep from './VerifyStep.vue';

export default {
  name: 'SettingsEmail',
  components: {
    SettingsOption,
    StepsWrapper,
    SmoothModal,

    CodeStep,
    ChangeStep,
    VerifyStep,
  },
  props: {
    isEmailVerified: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const { user } = useUser();
    const secretEmail = computed(() => {
      const email = user.value?.email;
      if (email) {
        const [name, domain] = email.split('@');
        return `${name.substr(0, 1)}*******${name.substr(-1)}@${domain}`;
      }
      return '';
    });
    const steps = ['code', 'change', 'verify'];
    const currentStep = ref('code');

    const isModalOpen = ref(false);
    const openModal = async (newStep) => {
      isModalOpen.value = true;
      currentStep.value = newStep;
    };

    const emailToVerify = ref('');

    const { addNotification } = useNotifications();
    const onSuccess = ({ text, step, newEmail }) => {
      addNotification({ text, config: { color: 'success' } });
      getSettings();

      emitter.emit('update-settings-page');
      emit('update');
      if (step) {
        currentStep.value = step;
      } else {
        isModalOpen.value = false;
      }

      if (newEmail) {
        emailToVerify.value = newEmail;
      }
    };

    const { t } = i18n.global;

    const title = computed(() => {
      if (currentStep.value === 'code') {
        return t('merchantSettings.changeEmail.title.enterTotp');
      }

      if (currentStep.value === 'change') {
        return t('merchantSettings.changeEmail.title.changeEmail');
      }

      return t('merchantSettings.changeEmail.title.verifyEmail');
    });

    const description = computed(() => {
      if (currentStep.value === 'code') {
        return t('merchantSettings.changeEmail.description.enterTotp');
      }

      if (currentStep.value === 'change') {
        return t('merchantSettings.changeEmail.description.changeEmail');
      }

      return t('merchantSettings.changeEmail.description.verifyEmail', { email: emailToVerify.value });
    });

    return {
      title,
      description,
      secretEmail,

      steps,
      currentStep,

      openModal,
      isModalOpen,

      onSuccess,
    };
  },
};
</script>
