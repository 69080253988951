<template>
  <div class="settings-data">
    <AppText class="title font-medium" size="12px" :opacity="0.4">
      {{ t('merchantSettings.label.yourData') }}
    </AppText>

    <FButton class="delete" type="transparent" @click="isModalToDeleteOpen = true">
      <AppIcon name="ban-block-slash" size="14px" :opacity="0.4" />
      <AppText class="font-medium" :opacity="0.4">
        {{ t('merchantSettings.label.deleteAccount') }}
      </AppText>
    </FButton>

    <div class="export">
      <FButton
        class="export-button"
        type="transparent"
        size="small"
        @click="onExport"
      >
        <AppIcon name="download-colored" size="14px" :opacity="0.4" />
        <AppText class="font-medium" :opacity="0.4">
          {{ t('merchantSettings.label.exportData') }}
        </AppText>
      </FButton>
    </div>
  </div>

  <DeleteAccountModal v-model="isModalToDeleteOpen" />
</template>

<script>
import { ref } from 'vue';

import { useFetch } from '@/api';
import { useUser } from '@/composables/useUser';
import { useNotifications } from '@/composables/useNotifications';
import { i18n } from '@/plugins/localization';
import { downloadAsFile } from '@/utils/functions';

import DeleteAccountModal from './DeleteAccountModal.vue';

export default {
  name: 'SettingsData',
  components: {
    DeleteAccountModal,
  },
  setup() {
    // await confirm({ useLocalModal: true });
    // If 2FA code or password is correct -> continue logic
    // For example 'delete account' or 'change some importnat option' which needs confirmation

    const isModalToDeleteOpen = ref(false);
    const isDataProcessing = ref(false);

    const { uid } = useUser();

    const { t } = i18n.global;
    const { addNotification } = useNotifications();

    const onExport = async () => {
      isDataProcessing.value = true;

      addNotification({
        text: t('merchantDashboard.statement.downloadProcessing'),
        config: { duration: 1000 },
      });

      const { axiosWrapper } = useFetch();
      const response = await axiosWrapper({
        type: 'get',
        // eslint-disable-next-line no-underscore-dangle
        url: '/merchants/export-data',
        responseType: 'blob',
        params: { isFreshResponse: true },
      });

      if (response.result?.data) {
        downloadAsFile({
          data: response.result.data,
          filename: `${uid.value}-export`,
          type: 'sqlite',
        });
      }

      isDataProcessing.value = false;
    };

    return {
      isDataProcessing,
      isModalToDeleteOpen,
      onExport,
    };
  },
};
</script>

<style lang="scss" scoped>
.settings-data {
  height: 68px;
  border-top: 1px solid var(--color-black-01);
  display: flex;
  align-items: center;

  .title {
    flex-grow: 1;
    text-transform: uppercase;
  }

  :deep(.el-button) {
    padding: 0;

    & > span {
      color: #13171A;
    }
  }

  .delete {
    padding-left: 0 !important;
    &:hover {
      color: var(--color-error);

      :deep(.icon) {
        opacity: 1;
        path {
          fill: var(--color-error);
        }
      }

      :deep(.text) {
        opacity: 1;
        color: var(--color-error);
      }
    }
  }

  .export {
    position: relative;

    .export-button {
      padding-left: 0 !important;
      margin-left: 20px !important;
      &:hover {
        color: var(--color-primary);

        :deep(.icon) {
          opacity: 1;

          path {
            stroke: var(--color-primary);
          }
        }

        :deep(.text) {
          opacity: 1;
          color: var(--color-primary);
        }
      }
    }
  }
}
</style>
