<template>
  <div class="settings-protect">
    <SettingsOption
      icon="protect-black"
      title="TOTP"
      :description="t('merchantSettings.changeToken.description')"
    >
      <template #append>
        <div class="right">
          <FButton
            type="text"
            size="small"
            @click="toggleModal"
          >
            {{ t('merchantSettings.changeToken.title') }}
          </FButton>
        </div>
      </template>
    </SettingsOption>

    <ModalToChangeTotp
      v-model="isModalOpen"
      mask="numsAndChars"
      :totp-function="generateQrCode"
      :qr-function="enableNewToken"
    />
  </div>
</template>

<script>
import { ref } from 'vue';

import ModalToChangeTotp from '@/components/Modules/ModalToChangeTotp';

import SettingsOption from '../../components/SettingsOption.vue';

import { enableNewToken, generateQrCode } from './api';

export default {
  name: 'SettingsProtect',
  components: {
    SettingsOption,
    ModalToChangeTotp,
  },
  emits: ['update'],
  setup() {
    const isModalOpen = ref(false);

    const toggleModal = () => {
      isModalOpen.value = !isModalOpen.value;
    };

    return {
      isModalOpen,
      toggleModal,

      enableNewToken,
      generateQrCode,
    };
  },
};
</script>
