<template>
  <div>
    <SettingsHeader :title="t('merchantSettings.security.title')" :last-updated="lastUpdated" />

    <div class="wrapper">
      <SettingsEmail :is-email-verified="isEmailVerified" @update="$emit('update')" />
      <SettingsProtect :status="settings.totp?.status" @update="$emit('update')" />

      <RecentActivity class="recent-activity" :data="data" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

import SettingsHeader from '../../components/SettingsHeader.vue';

import RecentActivity from './RecentActivity.vue';
import SettingsEmail from './SettingsEmail';
import SettingsProtect from './SettingsProtect.vue';

export default {
  name: 'SettingsSecurity',
  components: {
    SettingsHeader,
    SettingsEmail,
    SettingsProtect,
    RecentActivity,
  },
  props: {
    lastUpdated: {
      type: String,
      default: null,
    },
    settings: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update'],
  setup(props) {
    const isEmailVerified = computed(() => !props.settings.pendingEmail);

    return {
      isEmailVerified,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding-top: 10px;

  .recent-activity {
    margin-top: 40px;
  }
}
</style>
