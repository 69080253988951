import { logout } from '@/api/auth';
import { useFetch } from '@/composables/useFetch';
import { useNotifications } from '@/composables/useNotifications';
import { i18n } from '@/plugins/localization';

export const changeEmail = async (email) => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const { data, isSuccess, errorObject } = await axiosWrapper({
    type: 'patch',
    url: '/merchants/email',
    params: { email },
  });
  if (isSuccess) {
    successHandler(t('merchantSettings.notification.emailWasChangedSuccess'));
  }
  return {
    data, isSuccess, successHandler, errorObject,
  };
};

export const verifyEmail = async (code) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess, errorObject } = await axiosWrapper({
    type: 'post',
    url: '/settings/merchant/verify-email',
    params: { code: String(code) },
  });
  return { data, isSuccess, errorObject };
};

export const generateQrCode = async (token, label = 'Switch') => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess, errorObject } = await axiosWrapper({
    type: 'post',
    url: '/merchants/totp/change',
    params: { totpCode: String(token) },
  });

  const otpLink = isSuccess ? `otpauth://totp/${label}?secret=${data.secret}` : '';

  return { otpLink, isSuccess, errorObject };
};

export const enableNewToken = async (token) => {
  const { t } = i18n.global;
  const { axiosWrapper, successHandler } = useFetch();
  const {
    data, isSuccess, errorObject, error,
  } = await axiosWrapper({
    type: 'post',
    url: '/merchants/totp/recovery/enable',
    params: { totpCode: String(token) },
    useLogoutHandlerWhenError: false,
  });

  if (error?.data?.message === 'DUPLICATED_RECOVERY_SESSION') {
    const { addNotification } = useNotifications();
    addNotification({ text: t(`errors.${error.data.message}`), config: { color: 'error' } });
    logout({ pushToHome: true, useLogoutEndpoint: false });
  }

  if (isSuccess) {
    successHandler(t('merchantSettings.notification.totpWasChangedSuccess'));
  }

  return { data, isSuccess, errorObject };
};
